import React from "react"
import styled from "styled-components"
import { Layout, Pricing } from "../components"
import { graphql } from "gatsby"

const ServicesPage = ({ data }) => {
  const {
    allAirtable: { nodes: prices },
  } = data
  return (
    <Wrapper>
      <Layout>
        <Pricing prices={prices} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-grey-10);
  nav {
    background: var(--bcg-clr-primary);
  }
`

export const query = graphql`
  {
    allAirtable(
      filter: { table: { eq: "Pricing" } }
      sort: { order: ASC, fields: data___order }
    ) {
      nodes {
        id
        data {
          category
          price
          name
          description
        }
      }
    }
  }
`

export default ServicesPage
